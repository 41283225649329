import React from "react"
import styled from "styled-components"
import { SubHeaderText, SubContentText } from "../elements"
import { imageHover } from "../utilities/variables"

const StyledUSPLayout = styled.div`
  margin: 11px 13px;
  max-width: 270px;
  text-align: center;
  ${imageHover}
  h3 {
    font-weight: bold;
    margin: 15px 0 0 0;
  }
  .description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding: 0 40px 0 40px;
  }
  a {
    text-decoration: none;
  }
`

export const StyledUSP = ({ image, title, description }) => {
  return (
    <StyledUSPLayout>
      <div className="img-container">
        <img alt="USP" src={image} />
      </div>
      <SubHeaderText>{title}</SubHeaderText>
      <SubContentText
        dangerouslySetInnerHTML={{ __html: description }}
      ></SubContentText>
    </StyledUSPLayout>
  )
}
