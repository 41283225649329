import React from "react"
import styled from "styled-components"
import { ContentText, HeaderText, PrimaryBtn } from "../elements"
import { StyledBlog } from "./blog-layout"
import { Link } from "gatsby"

const StyledGridLayout = styled.div`
  text-align: center;
  > div {
    display: grid;
    margin: 0 auto;
    max-width: fit-content;
    gap: 20px;
    grid-template-columns: 1fr;
    @media (min-width: 550px) {
      grid-template-columns: ${props =>
        `repeat(${props.count >= 3 ? `2` : props.count}, 1fr)`};
    }
    @media (min-width: 950px) {
      grid-template-columns: ${props => `repeat(${props.count}, 1fr)`};
    }
  }
  > h2 {
    margin-bottom: 10px;
  }
  > p {
    max-width: 700px;
    margin: 0 auto 50px auto;
  }
  button {
    margin: 60px auto;
  }
  a {
    text-decoration: none;
  }
`

export const StyledBoxedGrid = ({ title, description, items }) => {
  const itemCount = Object.keys(items).length
  return (
    <StyledGridLayout count={itemCount}>
      <HeaderText>{title}</HeaderText>
      <ContentText
        sub
        dangerouslySetInnerHTML={{ __html: description }}
      ></ContentText>
      <div>
        {items.map(item => {
          return (
            <StyledBlog
              key={item.id}
              image={item.Image.publicURL}
              title={item.Title}
              description={item.ShortDescription}
              link={item.Slug}
            />
          )
        })}
      </div>
      <Link to={"/blogs"}>
        <PrimaryBtn>Read All</PrimaryBtn>
      </Link>
    </StyledGridLayout>
  )
}
