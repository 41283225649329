import styled from "styled-components"
import {
  colorWhite,
  colorGreyInput,
  CellBoxShadow,
} from "../utilities/variables"

export const StyledContentCell = styled.div`
  background: ${colorWhite} 0% 0% no-repeat padding-box;
  box-shadow: ${CellBoxShadow};
  border: 1px solid ${colorGreyInput};
  border-radius: 5px;
  padding: 11px 13px;
  max-width: max-content;
  font-family: "Sofia Pro";
`
