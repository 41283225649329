import React from "react"
import styled from "styled-components"
import {
  LgContentText,
  ContentText,
  HeaderText,
  SubHeaderText,
  SubContentText,
} from "../elements"
import {
  colorLinks,
  colorWhite,
  CellBoxShadow,
  colorGreyInput,
} from "../utilities/variables"

const StyledTeamLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px 72px;
  max-width: 1100px;
  text-align: start;
  margin: ${props => (props.small ? `0` : `30px`)};
  @media (min-width: 800px) {
    margin: ${props => (props.small ? `0` : `30px auto`)};
    grid-template-columns: 1fr 1fr;
  }
  ${props =>
    props.small &&
    `
    background: ${colorWhite} 0% 0% no-repeat padding-box;
    box-shadow: ${CellBoxShadow};
    border: 1px solid ${colorGreyInput};
    border-radius: 5px;
    grid-template-columns: 1fr !important;
    max-width: 270px;
    gap: 0;
    p {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-top: 0;
    }
  `}
  .image-container {
    overflow: hidden;
    @media (min-width: 800px) {
      height: auto;
    }
  }
  .team-grid__content {
    margin: auto;
    ${props =>
      props.small &&
      `
      padding: 0 20px 20px 20px;
    `}
    h3 {
      text-align: start;
    }
  }
  img {
    width: 100%;
    object-fit: cover;
    transition: transform 0.2s;
    height: ${props => (props.small ? `162px` : `260px`)};
    margin: ${props => (props.small ? `20px 0` : `0`)};
    @media (min-width: 800px) {
      height: ${props => (props.small ? `162px` : `310px`)};
      margin: ${props => (props.small ? `26px 0` : `50px 0`)};
    }
    &:hover {
      transform: scale(1.3);
    }
  }
  h2 {
    margin: 0;
  }
  h3 {
    font-weight: ${props => (props.small ? `bold` : `500`)};
    margin-bottom: 0;
    padding: 0;
  }
  h4 {
    margin: 0;
    padding: 0;
  }
  a {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 10px;
    margin-top: ${props => (props.small ? `0` : `35px`)};
    color: ${colorLinks};
    img {
      height: 20px;
      width: 20px;
    }
    p {
      color: ${colorLinks};
      margin: 0;
    }
  }
`

const StyledHeaderText = styled(HeaderText)`
  text-align: inherit;
  & + p {
    text-align: inherit;
  }
`

export const StyledTeam = ({
  small,
  image,
  title,
  jobtitle,
  description,
  link,
  handle,
}) => {
  return (
    <StyledTeamLayout
      small={small ? true : false}
      className={!small ? `cross` : ``}
    >
      <div className="image-container">
        <img src={image} alt="Team member" />
      </div>
      <div className="team-grid__content">
        {small ? (
          <>
            <SubHeaderText>{title}</SubHeaderText>
            <ContentText>{jobtitle}</ContentText>
            <SubContentText
              noMargin
              dangerouslySetInnerHTML={{ __html: description }}
            ></SubContentText>
            {/*
             <a target="_blank" href={`https://www.linkedin.com/${handle}`}>
              <img src={linkedin} alt="linkedin" />
              <ContentText>@{handle}</ContentText>
            </a> */}
          </>
        ) : (
          <>
            <StyledHeaderText>{title}</StyledHeaderText>
            <LgContentText>{jobtitle}</LgContentText>
            <ContentText
              dangerouslySetInnerHTML={{ __html: description }}
            ></ContentText>
            {/* <a target="_blank" href={`https://www.linkedin.com/${handle}`}>
              <img src={linkedin} alt="linkedin" />
              <ContentText>@{handle}</ContentText>
            </a> */}
          </>
        )}
      </div>
    </StyledTeamLayout>
  )
}
