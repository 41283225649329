import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { SubHeaderText, SubContentText, SmallContentText } from "../elements"
import {
  colorWhite,
  CellBoxShadow,
  colorGreyInput,
} from "../utilities/variables"

const StyledJobLayout = styled.div`
  background: ${colorWhite} 0% 0% no-repeat padding-box;
  box-shadow: ${CellBoxShadow};
  border: 1px solid ${colorGreyInput};
  border-radius: 5px;
  padding: 11px 13px;
  max-width: 366px;
  text-align: start;
  h3 {
    font-weight: bold;
    margin: 0;
    text-align: start;
  }
  > div {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 20px;
  }
  .description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  a {
    text-decoration: none;
  }
  p {
    p {
      margin: 0;
    }
  }
`

export const StyledJob = ({ title, description, link, field, location }) => {
  return (
    <StyledJobLayout>
      <SubHeaderText>{title}</SubHeaderText>
      <div>
        <SubContentText>{field}</SubContentText>
        <SubContentText>{location}</SubContentText>
      </div>
      <SubContentText
        className="description"
        dangerouslySetInnerHTML={{ __html: description }}
      ></SubContentText>
      <Link to={`/vacancies/${link}`}>
        <SmallContentText>Read more...</SmallContentText>
      </Link>
    </StyledJobLayout>
  )
}
