import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import {
  colorWhite,
  CellBoxShadow,
  colorGreyInput,
  imageHover,
} from "../utilities/variables"
import {
  SubHeaderText,
  SubContentText,
  SmallContentText,
} from "../elements/typography"

const StyledBlogLayout = styled.div`
  background: ${colorWhite} 0% 0% no-repeat padding-box;
  box-shadow: ${CellBoxShadow};
  border: 1px solid ${colorGreyInput};
  border-radius: 5px;
  max-width: ${props => (props.large ? `560px` : `270px`)};
  ${imageHover}
  h3 {
    margin: 15px 0;
  }
  p {
    display: -webkit-box;
    -webkit-line-clamp: ${props => (props.large ? `3` : `2`)};
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .content {
    padding: 0 20px 10px 20px;
  }
  small {
    cursor: pointer;
  }
  a {
    text-decoration: none;
  }
`

export const StyledBlog = ({ image, large, title, description, link }) => {
  return (
    <StyledBlogLayout large={large}>
      <div className="img-container">
        <img alt="blog" src={image} />
      </div>
      <div className="content">
        <SubHeaderText>{title}</SubHeaderText>
        <SubContentText
          dangerouslySetInnerHTML={{ __html: description }}
        ></SubContentText>
        <Link to={link}>
          <SmallContentText>Read more…</SmallContentText>
        </Link>
      </div>
    </StyledBlogLayout>
  )
}
