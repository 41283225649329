import React from "react"
import styled from "styled-components"
import { ContentText, HeaderText } from "../elements"

const StyledTextLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px 72px;
  max-width: 1100px;
  text-align: start;
  margin: 30px;
  padding: 20px 0;
  @media (min-width: 800px) {
    margin: 30px auto;
    grid-template-columns: 1fr 1fr;
  }
  .text-grid__content {
    margin: auto 0;
    h2 {
      text-align: inherit;
    }
  }
  .image-container {
    overflow: hidden;
    height: 310px;
  }
  img {
    height: 310px;
    width: 100%;
    object-fit: cover;
    transition: transform 0.2s;
    &:hover {
      transform: scale(1.3);
    }
  }
  h2 {
    margin: 0;
  }
`

export const StyledText = ({ image, title, description, cross }) => {
  return (
    <StyledTextLayout className={cross ? `cross` : ``}>
      <div className="image-container">
        <img src={image} alt="Team member" />
      </div>
      <div className="text-grid__content">
        <HeaderText>{title}</HeaderText>
        <ContentText
          dangerouslySetInnerHTML={{ __html: description }}
        ></ContentText>
      </div>
    </StyledTextLayout>
  )
}
