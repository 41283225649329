import React from "react"
import styled from "styled-components"
import {
  ContentText,
  HeaderText,
  SubHeaderText,
  BulletPointContainer,
  BulletPointNumber,
  SubContentText,
} from "../elements"
import GooglePlay from "../assets/images/icons/google-play-badge.png"
import AppStore from "../assets/images/icons/apple-store-badge.png"

const StyledLayout = styled.div`
  text-align: center;
  > div {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1064px;
    margin: auto;
    padding: 0 20px;
    @media (min-width: 1000px) {
      padding: 0;
    }
    @media (min-width: 850px) {
      grid-template-columns: max-content 1fr max-content;
    }
    img {
      width: 100%;
      max-width: 500px;
      margin: auto;
      @media (min-width: 850px) {
        grid-area: 1 / 2 / 3 / 3;
      }
    }
    .item {
      text-align: start;
      padding: 0 40px;
      h3 {
        text-align: start;
      }
      @media (min-width: 500px) {
        padding: 0;
      }
    }
    .top-left {
      align-self: center;
      @media (min-width: 850px) {
        grid-area: 1 / 1 / 2 / 2;
      }
    }
    .top-right {
      align-self: center;
      @media (min-width: 850px) {
        grid-area: 1 / 3 / 2 / 4;
      }
    }
    .bottom-left {
      @media (min-width: 850px) {
        grid-area: 2 / 1 / 3 / 2;
      }
    }
    .bottom-right {
      @media (min-width: 850px) {
        grid-area: 2 / 3 / 3 / 4;
      }
    }
    .bottom {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      width: max-content;
      gap: 15px;
      margin: 0 auto 30px auto;
      @media (min-width: 850px) {
        grid-area: 3 / 2 / 4 / 3;
      }
    }
  }
  > h2 {
    margin-bottom: 10px;
  }
  > p {
    max-width: 700px;
    margin: 0 auto 50px auto;
  }
`

export const AppLayout = ({
  title,
  description,
  items,
  image,
  Google,
  Apple,
}) => {
  const itemCount = Object.keys(items).length
  return (
    <StyledLayout count={itemCount}>
      <HeaderText>{title}</HeaderText>
      <ContentText
        sub
        dangerouslySetInnerHTML={{ __html: description }}
      ></ContentText>
      <div>
        <img src={image} alt="header" />
        <div className="item top-left">
          <BulletPointContainer>
            <BulletPointNumber>1</BulletPointNumber>
            <SubHeaderText>{items[0].Title}</SubHeaderText>
            <SubContentText>{items[0].Description}</SubContentText>
          </BulletPointContainer>
        </div>
        <div className="item top-right">
          <BulletPointContainer>
            <BulletPointNumber>2</BulletPointNumber>
            <SubHeaderText>{items[1].Title}</SubHeaderText>
            <SubContentText>{items[1].Description}</SubContentText>
          </BulletPointContainer>
        </div>
        <div className="item bottom-left">
          <BulletPointContainer>
            <BulletPointNumber>3</BulletPointNumber>
            <SubHeaderText>{items[2].Title}</SubHeaderText>
            <SubContentText>{items[2].Description}</SubContentText>
          </BulletPointContainer>
        </div>
        <div className="item bottom-right">
          <BulletPointContainer>
            <BulletPointNumber>4</BulletPointNumber>
            <SubHeaderText>{items[3].Title}</SubHeaderText>
            <SubContentText>{items[3].Description}</SubContentText>
          </BulletPointContainer>
        </div>
        <div className="bottom">
          <a href={Apple} target="_blank" rel="noreferrer">
            <img src={AppStore} alt="AppStore" />
          </a>
          <a href={Google} target="_blank" rel="noreferrer">
            <img src={GooglePlay} alt="GooglePlay" />
          </a>
        </div>
      </div>
    </StyledLayout>
  )
}
